import React from 'react'

import PostLinks from '../components/PostLinks'
import PostSection from '../components/PostSection'

const Post = postContext => {
  const { body, context } = postContext

  return (
    <PostSection {...postContext}>
      <div className="col-12 col-md-10">
        <div
          className="single-post-text"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
      <div className="col-12">
        <PostLinks {...context} />
      </div>
    </PostSection>
  )
}

export default Post
