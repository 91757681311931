import React from 'react'
import { Link } from 'gatsby'

import { getPostPath } from '../utils/helpers'

const PostLinks = ({ prev, next }) => {
  const prevPageLink = prev && getPostPath(prev)
  const nextPageLink = next && getPostPath(next)
  return (
    <div className="row">
      <div className="col-12">
        <div className="gazette-pagination-area">
          <ul className="pagination-lg">
            <div className="d-sm-flex align-items-center justify-content-between">
              <div className="col-12 col-lg-6">
                {prev ? (
                  <li className="page-item">
                    <Link className="page-link" to={prevPageLink}>
                      <i className="fa fa-angle-double-left" />  {prev.frontmatter.title}
                    </Link>
                  </li>
                ) : (
                  <li className="page-item disabled" />
                )}
              </div>
              <div className="col-12 col-lg-6">
                {next ? (
                  <li className="page-item">
                    <Link className="page-link" to={nextPageLink}>
                      {next.frontmatter.title} <i className="fa fa-angle-double-right" />
                    </Link>
                  </li>
                ) : (
                  <li className="page-item disabled" />
                )}
              </div>
            </div>
          </ul>

        </div>
      </div>
    </div>
  )
}

export default PostLinks
